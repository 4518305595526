import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import "channels"

import Masonry from "masonry-layout/dist/masonry.pkgd.min.js"
import imagesLoaded from 'imagesloaded'

document.addEventListener("turbolinks:load", function () {
  const containers = document.querySelectorAll('.is-masonry');
  containers.forEach(container => {
    imagesLoaded(container, function () {
      const masonry = new Masonry(container, {
      });
      masonry.layout();
    });
  });
});

window.reloadMasonry = function() {
  const containers = document.querySelectorAll('.is-masonry');
  containers.forEach(container => {
    imagesLoaded(container, function () {
      const masonry = new Masonry(container, {
      });
      masonry.layout();
    });
  });
}

Rails.start()
Turbolinks.start()

import "controllers"
